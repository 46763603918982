body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
  display: block;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-size: 1em;
  line-height: 1.35;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

::-moz-selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #fff;
  text-shadow: none;
}

a {
  text-decoration: none;
}

a:focus,
a:hover,
a:active {
  outline: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 0;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q::before,
q::after {
  content: "";
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

nav ul,
nav ol,
ul,
li {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type=file] {
  cursor: pointer;
}
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type=text],
input[type=email],
input[type=password],
input[type=search],
textarea {
  box-shadow: none;
  appearance: none;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  display: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  background-color: #f0dddd;
}

input,
textarea,
select {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: transparent;
}

td {
  vertical-align: top;
}

@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Medium.eot");
  src: url("/assets/fonts/whitney/Whitney-Medium.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Medium.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Medium.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-MediumItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-MediumItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-MediumItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Bold.eot");
  src: url("/assets/fonts/whitney/Whitney-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Bold.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Bold.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BoldItalicSC.eot");
  src: url("/assets/fonts/whitney/Whitney-BoldItalicSC.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BoldItalicSC.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BoldItalicSC.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Light.eot");
  src: url("/assets/fonts/whitney/Whitney-Light.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Light.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Light.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-LightItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-LightItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-LightItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-LightItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Book.eot");
  src: url("/assets/fonts/whitney/Whitney-Book.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Book.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Book.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Book.ttf") format("truetype");
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BookItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-BookItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BookItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-BookItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BookItalic.ttf") format("truetype");
  font-weight: 350;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-Semibold.eot");
  src: url("/assets/fonts/whitney/Whitney-Semibold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-Semibold.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-Semibold.woff") format("woff"), url("/assets/fonts/whitney/Whitney-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-SemiboldItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-SemiboldItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-SemiboldItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BlackSC.eot");
  src: url("/assets/fonts/whitney/Whitney-BlackSC.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BlackSC.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-BlackSC.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BlackSC.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Whitney";
  src: url("/assets/fonts/whitney/Whitney-BlackItalic.eot");
  src: url("/assets/fonts/whitney/Whitney-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/whitney/Whitney-BlackItalic.woff2") format("woff2"), url("/assets/fonts/whitney/Whitney-BlackItalic.woff") format("woff"), url("/assets/fonts/whitney/Whitney-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.woff2") format("woff2"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.woff") format("woff"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.ttf") format("truetype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.woff2") format("woff2"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.woff") format("woff"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.ttf") format("truetype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.woff2") format("woff2"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.woff") format("woff"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.ttf") format("truetype"), url("/assets/fonts/google/roboto-v27-latin-ext_latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/google/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("/assets/fonts/google/MaterialIcons-Regular.woff2") format("woff2"), url("/assets/fonts/google/MaterialIcons-Regular.woff") format("woff"), url("/assets/fonts/google/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.button {
  position: relative;
  display: flex;
  justify-content: center;
  height: 50px;
  line-height: 46px;
  min-width: 300px;
  padding: 0 34px;
  align-self: center;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  background-color: #063A89;
  border: 2px solid #063A89;
  border-radius: 25px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .button {
    min-width: 0;
    padding: 0 20px;
    font-size: 12px;
  }
}
.button:hover {
  background-color: #042E59;
  border-color: #042E59;
}

.button--arrow:hover:before {
  margin-right: 0;
  margin-left: 13px;
}
.button--arrow:before, .button--arrow:after {
  display: block;
  content: "";
  background-color: #ffffff;
  border-color: #ffffff;
}
.button--arrow:before {
  align-self: center;
  order: 1;
  height: 2px;
  width: 9px;
  margin-right: -2px;
  margin-left: 10px;
  background-color: #ffffff;
  transition: margin 100ms ease;
}
.button--arrow:after {
  align-self: center;
  order: 2;
  width: 0;
  height: 0;
  background-color: transparent !important;
  border: 5px solid;
  border-bottom: 5px solid transparent !important;
  border-left: 5px solid transparent !important;
  transform: rotate(45deg);
}

.button--arrow--back:hover:before {
  margin-left: 0;
  margin-right: 13px;
}
.button--arrow--back:before, .button--arrow--back:after {
  display: block;
  content: "";
  background-color: #ffffff;
  border-color: #ffffff;
}
.button--arrow--back:before {
  align-self: center;
  order: 0;
  height: 2px;
  width: 9px;
  margin-left: -2px;
  margin-right: 10px;
  background-color: #ffffff;
  transition: margin 100ms ease;
}
.button--arrow--back:after {
  align-self: center;
  order: -1;
  width: 0;
  height: 0;
  background-color: transparent !important;
  border: 5px solid;
  border-top: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  transform: rotate(45deg);
}

.button--border {
  background-color: transparent;
  color: #063A89;
}
.button--border:before, .button--border:after {
  background-color: #063A89;
  border-color: #063A89;
}
.button--border:hover {
  background-color: #063A89;
  border-color: #063A89;
  color: #ffffff;
}
.button--border:hover:before, .button--border:hover:after {
  background-color: #ffffff;
  border-color: #ffffff;
}

.button--light-blue {
  background-color: #6CD1DD;
  border-color: #6CD1DD;
  color: #02162B;
}
.button--light-blue:hover {
  background-color: #3dc4d3;
  border-color: #6CD1DD;
}
.button--light-blue:before, .button--light-blue:after {
  background-color: #02162B;
  border-color: #02162B;
}
.button--light-blue.button--border {
  background-color: transparent;
  color: #6CD1DD;
  border-color: #6CD1DD;
}
.button--light-blue.button--border:before, .button--light-blue.button--border:after {
  background-color: #6CD1DD;
  border-color: #6CD1DD;
}
.button--light-blue.button--border:hover {
  background-color: #6CD1DD;
  color: #02162B;
}
.button--light-blue.button--border:hover:before, .button--light-blue.button--border:hover:after {
  background-color: #02162B;
  border-color: #02162B;
}

.container.buttons-container {
  margin: 50px 0;
}
.container.buttons-container .inner {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
.container.buttons-container .button {
  min-width: 422px;
  align-self: center;
}
@media (max-width: 767px) {
  .container.buttons-container .button {
    min-width: 0;
    max-width: 422px;
    width: 100%;
  }
}
.container.buttons-container .button + .button {
  margin-top: 30px;
}

.button--loading {
  position: relative;
  opacity: 0.5;
  pointer-events: none;
}
.button--loading:after {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px dotted #063A89;
  background-color: #ffffff;
  border-radius: 50%;
  border-bottom: none;
  animation: 1.5s linear rotate infinite;
  position: absolute;
  right: 18px;
  top: 18px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.form {
  margin: 70px 0;
}
@media (max-width: 767px) {
  .form {
    margin: 30px 0;
  }
}
@media (max-width: 767px) {
  .form .button {
    margin-top: 20px;
  }
}

.form__intro {
  margin: 38px 0 35px;
  max-width: 520px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #00378C;
}

.form__suptitle {
  margin-bottom: 9px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.17em;
  font-weight: 350;
  text-transform: uppercase;
  color: #02162B;
}

.form__title {
  margin-bottom: 23px;
}

.form__description {
  margin-bottom: 65px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 15px;
  line-height: 22px;
  font-weight: 350;
  color: #02162B;
}

.form__section {
  margin-top: 40px;
  border-top: 1px solid #D4D4D4;
  padding-top: 70px;
}

.form__section__title {
  margin-bottom: 40px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  color: #02162B;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}

.form__row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .form__row {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .form__row millad-slider-input {
    width: auto;
    align-self: flex-start;
  }
}

.form__row__info {
  width: 100%;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 22px;
  line-height: 25px;
  font-weight: 400;
  color: #FF671F;
}

.form__row--double millad-unit-input, .form__row--double millad-select-input, .form__row--double millad-slider-input {
  width: calc(50% - 50px);
}
@media (max-width: 1024px) {
  .form__row--double millad-unit-input, .form__row--double millad-select-input, .form__row--double millad-slider-input {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .form__row--double millad-unit-input, .form__row--double millad-select-input, .form__row--double millad-slider-input {
    width: auto;
    align-self: flex-start;
  }
}
@media (max-width: 550px) {
  .form__row--double millad-unit-input, .form__row--double millad-select-input, .form__row--double millad-slider-input {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .form__row--double millad-unit-input .unit-input, .form__row--double millad-select-input .unit-input, .form__row--double millad-slider-input .unit-input {
    align-self: center;
  }
}
@media (max-width: 1024px) {
  .form__row--double millad-unit-input .unit-input .unit-input__value__input, .form__row--double millad-select-input .unit-input .unit-input__value__input, .form__row--double millad-slider-input .unit-input .unit-input__value__input {
    width: 200px;
  }
}
@media (max-width: 767px) {
  .form__row--double millad-unit-input .unit-input .unit-input__value__input, .form__row--double millad-select-input .unit-input .unit-input__value__input, .form__row--double millad-slider-input .unit-input .unit-input__value__input {
    width: 300px;
  }
}
@media (max-width: 1024px) {
  .form__row--double millad-unit-input .unit-input .unit-input__value__unit, .form__row--double millad-select-input .unit-input .unit-input__value__unit, .form__row--double millad-slider-input .unit-input .unit-input__value__unit {
    left: 210px;
  }
}
@media (max-width: 767px) {
  .form__row--double millad-unit-input .unit-input .unit-input__value__unit, .form__row--double millad-select-input .unit-input .unit-input__value__unit, .form__row--double millad-slider-input .unit-input .unit-input__value__unit {
    left: 310px;
  }
}
.form__row--double > .unit-display, .form__row--double .unit-display__savings {
  width: calc(50% - 50px);
}
@media (max-width: 1024px) {
  .form__row--double > .unit-display, .form__row--double .unit-display__savings {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .form__row--double > .unit-display, .form__row--double .unit-display__savings {
    width: 100%;
    align-self: center;
  }
}
@media (max-width: 550px) {
  .form__row--double > .unit-display, .form__row--double .unit-display__savings {
    width: 100%;
  }
}

.form__row--margin-bottom {
  margin-bottom: 30px;
}

.form__row--actions {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .form__row--actions {
    flex-direction: column;
  }
}
.form__row--actions .button {
  width: calc(50% - 50px);
}
@media (max-width: 767px) {
  .form__row--actions .button {
    margin-bottom: 10px;
    width: 100%;
  }
}

hr {
  margin: 52px 0 70px;
  border-color: #D4D4D4;
}
@media (max-width: 767px) {
  hr {
    margin-top: 22px;
    margin-bottom: 40px;
  }
}

.info-with-checkbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;
}
@media (max-width: 767px) {
  .info-with-checkbox {
    flex-direction: column;
  }
}

.info-with-checkbox__info {
  max-width: 623px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 15px;
  font-weight: 350;
  line-height: 22px;
  color: #000000;
}

.info-with-checkbox--only-info .info-with-checkbox__info {
  max-width: 100%;
}

@media (max-width: 767px) {
  .info-with-checkbox__checkbox {
    margin-top: 30px;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.6px;
}

h1, .h1 {
  font-size: 62px;
  line-height: 60px;
  letter-spacing: 0.17em;
  font-weight: 500;
}
@media (max-width: 767px) {
  h1, .h1 {
    font-size: 42px;
    line-height: 44px;
  }
}
@media (max-width: 400px) {
  h1, .h1 {
    font-size: 30px;
    line-height: 32px;
  }
}

h2, .h2 {
  font-size: 32px;
  line-height: 35px;
  font-weight: 600;
  color: #02162B;
  text-align: center;
}

h3, .h3 {
  font-size: 32px;
  line-height: 35px;
  font-weight: 300;
}

h4, .h4 {
  font-size: 21px;
  line-height: 32px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.align-left {
  text-align: left;
}

* {
  box-sizing: border-box;
}
*:focus, *:active {
  outline: none;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.container .inner {
  max-width: 640px;
  width: 100%;
  padding: 0 20px;
}
.container .inner--medium {
  max-width: 1080px;
  margin: 0 auto;
}
.container .inner--big {
  max-width: 1240px;
  margin: 0 auto;
}
.container .inner--extra-big {
  max-width: 1260px;
  margin: 0 auto;
}

@media (min-width: 1025px) {
  .outside-parent {
    margin-left: -100px;
    margin-right: -100px;
  }
}

.container.intro {
  margin-top: 145px;
  margin-bottom: 78px;
}
.container.intro h1 {
  max-width: 480px;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}

.gradient {
  background: linear-gradient(180deg, #00378c 0%, #003586 7%, #021222 100%);
  color: #ffffff;
}
.gradient * {
  color: #ffffff;
}
.gradient label, .gradient .unit-input__value__unit {
  color: #D4D4D4 !important;
}
.gradient input {
  color: #ffffff !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.unit-input {
  margin-bottom: 30px;
}
.unit-input label {
  margin-bottom: 7px;
  display: inline-block;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}

.unit-input--readonly label {
  color: #063a89;
  cursor: text;
}
.unit-input--readonly .unit-input__value__input {
  border-color: #063a89;
}

.unit-input--no-unit {
  width: 100%;
}
.unit-input--no-unit .unit-input__value__input {
  width: 100% !important;
}

.unit-input__value {
  position: relative;
  display: flex;
}

.unit-input__pdf-value {
  display: none;
}

.unit-input__value__input {
  flex-shrink: 0;
  width: 300px;
  height: 28px;
  border: none;
  border-bottom: 2px solid #82cfdc;
}
@media (max-width: 550px) {
  .unit-input__value__input {
    width: calc(100% - 100px) !important;
  }
}
.unit-input__value__input:focus {
  border-color: #063a89;
}
.unit-input__value__input.error {
  border-color: #ff0000;
}
.unit-input__value__input[disabled] {
  border-bottom-color: transparent;
}
.unit-input__value__input[readonly] {
  border-bottom-color: #d4d4d4;
  color: #888888;
}

input.unit-input__value__input {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #00378b;
  letter-spacing: 0.17em;
  background-color: transparent;
  text-transform: uppercase;
}

.unit-input__value__unit {
  position: absolute;
  left: 310px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #d4d4d4;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  white-space: nowrap;
}
@media (max-width: 550px) {
  .unit-input__value__unit {
    position: static;
    min-width: 110px;
    margin-left: 10px;
    transform: none;
  }
}

.unit-input__error {
  margin-top: 2px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.17em;
  text-transform: uppercase;
  color: #ff0000;
}

.questionnaire {
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
}

.questionnaire__question {
  padding-top: 59px;
  display: flex;
  flex-direction: column;
}

.questionnaire__question__intro {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 32px;
  line-height: 52px;
  font-weight: 600;
  color: #02162B;
  text-align: center;
}

.questionnaire__question__answers {
  margin: 40px -1.5px 0;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .questionnaire__question__answers {
    flex-direction: column;
  }
}

.questionnaire__question__answers__answer {
  margin: 0 1.5px;
  display: block;
  width: 298px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .questionnaire__question__answers__answer {
    display: flex;
    width: 100%;
  }
  .questionnaire__question__answers__answer + .questionnaire__question__answers__answer {
    margin-top: 15px;
  }
}
.questionnaire__question__answers__answer:hover .questionnaire__question__answers__answer__image {
  background-size: 105% 105%;
  box-shadow: inset 0px 0px 50px 50px rgba(0, 0, 0, 0.1);
}
.questionnaire__question__answers__answer:hover .questionnaire__question__answers__answer__title {
  color: #02162B;
}

.questionnaire__question__answers__answer__image {
  width: 100%;
  padding-top: 100%;
  background-size: 100% 100%;
  background-position: center;
  box-shadow: inset 0px 0px 50px 50px rgba(0, 0, 0, 0);
  transition: all 300ms ease;
}
@media (max-width: 767px) {
  .questionnaire__question__answers__answer__image {
    flex-shrink: 0;
    width: 100px;
    padding-top: 100px;
  }
}

.questionnaire__question__answers__answer__title {
  margin-top: 9px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
  color: #00378C;
  transition: color 150ms ease;
}
@media (max-width: 767px) {
  .questionnaire__question__answers__answer__title {
    width: 100%;
    padding-left: 20px;
    align-self: center;
    line-height: 20px;
    font-size: 20px;
  }
}

.questionnaire__question__answers__answer__description {
  padding-right: 28px;
  margin-top: 2px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 200;
  color: #000000;
}

.questionnaire__form {
  display: flex;
  flex-direction: column;
}
.questionnaire__form .unit-input {
  padding-right: 75px;
}
.questionnaire__form .unit-input label {
  pointer-events: none;
}
.questionnaire__form h2 {
  max-width: 820px;
  align-self: center;
  margin: 59px 0 32px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 62px;
  line-height: 60px;
  font-weight: 400;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}
.questionnaire__form .form__intro {
  display: flex;
  max-width: 100%;
  font-size: 28px;
  line-height: 36px;
}
.questionnaire__form .form__intro img {
  margin-right: 70px;
}
.questionnaire__form .form__row--actions {
  justify-content: center;
}

.questionnaire__back {
  align-self: flex-start;
  min-width: 154px;
}

.questionnaire-result__graph {
  margin-top: 75px;
  padding: 60px 0 104px;
  background-color: #02162B;
}

.questionnaire-result__graph__title {
  margin-bottom: 60px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 32px;
  line-height: 52px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
}

.questionnaire-result__graph__wrapper {
  display: flex;
}

.questionnaire-result__graph__wrapper__plot {
  position: relative;
  width: 71%;
}

.questionnaire-result__graph__wrapper__plot__y-axis {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48px;
  height: 412px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.questionnaire-result__graph__wrapper__plot__y-axis__label {
  position: absolute;
  left: -45px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

.questionnaire-result__graph__wrapper__plot__y-axis__value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #ffffff;
}

.questionnaire-result__graph__wrapper__plot__y-axis__value--value1 {
  padding-top: 60px;
}

.questionnaire-result__graph__wrapper__plot__y-axis__value--value2 {
  padding-bottom: 60px;
}

.questionnaire-result__graph__wrapper__plot__x-axis {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -30px;
  left: 48px;
  right: 59px;
  height: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.questionnaire-result__graph__wrapper__plot__x-axis__label {
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

.questionnaire-result__graph__wrapper__plot__x-axis__value {
  padding-top: 27px;
  width: 50%;
  text-align: center;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #ffffff;
}

.questionnaire-result__graph__wrapper__plot__content {
  position: absolute;
  top: 0;
  right: 50px;
  bottom: 0;
  left: 48px;
}

.questionnaire-result__graph__wrapper__plot__content__left {
  position: absolute;
  left: 128px;
  bottom: 117px;
  padding: 22px 44px 22px 28px;
  background-color: #F65275;
  border-radius: 14px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}

.questionnaire-result__graph__wrapper__plot__content__left__label {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%) translateY(5px);
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  color: #F65275;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}

.questionnaire-result__graph__wrapper__plot__content__right {
  position: absolute;
  right: 5px;
  top: 95px;
  padding: 22px 44px 22px 28px;
  background-color: #00378C;
  border-radius: 14px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}

.questionnaire-result__graph__wrapper__plot__content__right__label {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%) translateY(5px);
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 10px;
  line-height: 10px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}

.questionnaire-result__graph__wrapper__plot__content__arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30%;
  height: 30%;
  background-image: url("/assets/images/savings/graph-arrow.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-36px, -70px);
}

.questionnaire-result__graph__wrapper__legend {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 29%;
}

.questionnaire-result__graph__wrapper__legend__top {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
}

.questionnaire-result__graph__wrapper__legend__top__item {
  display: flex;
  margin-bottom: 30px;
}

.questionnaire-result__graph__wrapper__legend__top__item--pink .questionnaire-result__graph__wrapper__legend__top__item__color {
  background-color: #68D2DF;
}
.questionnaire-result__graph__wrapper__legend__top__item--pink .questionnaire-result__graph__wrapper__legend__top__item__label {
  color: #ffffff;
}

.questionnaire-result__graph__wrapper__legend__top__item--blue .questionnaire-result__graph__wrapper__legend__top__item__color {
  background-color: #F65275;
}
.questionnaire-result__graph__wrapper__legend__top__item--blue .questionnaire-result__graph__wrapper__legend__top__item__label {
  color: #F65275;
}

.questionnaire-result__graph__wrapper__legend__top__item__color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: grey;
}

.questionnaire-result__graph__wrapper__legend__top__item__label {
  margin-left: 18px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  color: grey;
}

.questionnaire-result__graph__wrapper__legend__bottom {
  width: 100%;
  padding: 29px 35px 31px 39px;
  background-color: rgba(0, 55, 139, 0.85);
}

.questionnaire-result__graph__wrapper__legend__bottom__title {
  margin-bottom: 20px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  color: #ffffff;
}

.questionnaire-result__graph__wrapper__legend__bottom__row {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 14px;
  line-height: 29px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  color: #D4D4D4;
}
.questionnaire-result__graph__wrapper__legend__bottom__row b {
  display: inline-block;
  min-width: 60px;
  font-size: 19px;
  line-height: 29px;
  font-weight: 500;
  color: #ffffff;
}
.questionnaire-result {
  padding-top: 70px;
  flex-direction: column;
}
@media (max-width: 767px) {
  .questionnaire-result {
    padding-top: 50px;
  }
}
.questionnaire-result h2 {
  max-width: 820px;
  align-self: center;
  margin: 59px auto 32px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 62px;
  line-height: 60px;
  font-weight: 400;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}
.questionnaire-result .form__intro {
  display: flex;
  max-width: 100%;
  font-size: 28px;
  line-height: 36px;
}
.questionnaire-result .form__intro img {
  margin-right: 70px;
}
.questionnaire-result .questionnaire__back {
  display: inline-flex;
}

.questionnaire__result__expressedas {
  display: flex;
  justify-content: center;
  padding: 70px 0 80px;
}
.questionnaire__result__expressedas .inner {
  max-width: 936px;
}

.questionnaire__result__expressedas__title {
  margin-bottom: 40px;
  text-align: center;
  padding: 0 100px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 32px;
  line-height: 52px;
  font-weight: 600;
  color: #000000;
}
.questionnaire__result__expressedas__title span {
  margin-top: -10px;
  font-size: 24px;
  display: block;
}

.questionnaire__result__expressedas__row {
  display: flex;
}
.questionnaire__result__expressedas__row + .questionnaire__result__expressedas__row {
  margin-top: 50px;
}

.questionnaire__result__expressedas__row__icon {
  width: 43px;
  height: 49px;
  margin-right: 44px;
  flex-shrink: 0;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.questionnaire__result__expressedas__row__info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.questionnaire__result__expressedas__row__info__label {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #D4D4D4;
}

.questionnaire__result__expressedas__row__info__name {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #00378C;
  text-transform: uppercase;
}

.questionnaire__result__expressedas__row__value {
  align-self: center;
  width: 215px;
  flex-shrink: 0;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 42px;
  line-height: 28px;
  font-weight: 400;
  color: #00378C;
}
.questionnaire__result__expressedas__row__value .small {
  padding-left: 56px;
  font-size: 18px;
}

.table {
  width: 100%;
  border: 1px solid #D4D4D4;
}
.table th, .table td {
  padding: 25px 30px 20px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-size: 12px;
  line-height: 13px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  border-left: 1px solid #D4D4D4;
  border-right: 1px solid #D4D4D4;
  text-transform: uppercase;
  letter-spacing: 0.17em;
}
.table th.table__align-left, .table td.table__align-left {
  text-align: left;
}
.table thead tr {
  background-color: #ffffff;
}
.table thead tr th.table__align-left {
  text-align: left;
}
.table thead tr th.table__col1 {
  color: #00378C;
}
.table thead tr th.table__col2 {
  color: #68D2DF;
}
.table thead tr th.table__col3 {
  color: #F65275;
}
.table tbody tr {
  background-color: #ffffff;
}
.table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 55, 140, 0.05);
}
.info {
  max-width: 850px;
  width: 100%;
  display: block;
  margin: 0 auto;
  padding-top: 235px;
  padding-bottom: 100px;
  justify-content: center;
  font-size: 1em;
  line-height: 1.35;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
}
.info h1,
.info h2,
.info h3,
.info h4 {
  color: black;
  margin-bottom: 20px;
  margin-top: 20px;
}
.info h4 {
  font-weight: 600;
}
.info p {
  margin-bottom: 20px;
}
.info p .bolded {
  font-weight: bold;
}
.info .links p {
  margin: 0;
}
.info table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.info td,
.info th {
  text-align: left;
  padding: 8px;
}
.info tr:nth-child(even) {
  background-color: #dddddd;
}

.selectize-control {
  position: relative;
  flex-shrink: 0;
  width: 300px;
  height: 28px;
  border: none;
  border-bottom: 2px solid #82cfdc;
  z-index: 1;
}
@media (max-width: 550px) {
  .selectize-control {
    width: calc(100% - 100px) !important;
  }
}
.selectize-control .selectize-input {
  position: relative;
}
.selectize-control .selectize-input .item {
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #00378b;
  letter-spacing: 0.17em;
}
.selectize-control .selectize-input input {
  position: absolute !important;
  width: 0;
  height: 0;
  opacity: 0 !important;
  pointer-events: none !important;
}
.selectize-control .selectize-input:after {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  margin: 5px;
  border: 5px solid transparent;
  border-top: 5px solid #00378B;
  border-right: 5px solid #00378B;
  transform: rotate(135deg);
  transform-origin: center center;
}
.selectize-control .selectize-dropdown {
  top: auto !important;
  bottom: 0;
}
.selectize-control .selectize-dropdown .selectize-dropdown-content {
  padding: 5px 0;
  background: #ffffff;
  border-bottom: 2px solid #82cfdc;
  box-shadow: 0 2px 2px 2px rgba(130, 207, 220, 0.1);
  max-height: 250px;
  overflow: auto;
}
.selectize-control .selectize-dropdown .selectize-dropdown-content .option {
  padding: 5px;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.17em;
  background-color: transparent;
  transition: all 250ms ease;
}
.selectize-control .selectize-dropdown .selectize-dropdown-content .option:hover {
  background-color: #eeeeee;
}
.selectize-control .selectize-dropdown .selectize-dropdown-content .option.selected {
  background-color: #eeeeee;
}