@import "../variables";
@import "../breakpoints";

.questionnaire {
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
}

  .questionnaire__question {
    padding-top: 59px;
    display: flex;
    flex-direction: column;
  }

    .questionnaire__question__intro {
      font-family: $whitney;
      font-size: 32px;
      line-height: 52px;
      font-weight: 600;
      color: #02162B;
      text-align: center;
    }

    .questionnaire__question__answers {
      margin: 40px -1.5px 0;
      display: flex;
      justify-content: center;

      @include mq('smartphone'){
        flex-direction: column;
      }
    }

    .questionnaire__question__answers__answer {
      margin: 0 1.5px;
      display: block;
      width: 298px;
      cursor: pointer;

      @include mq('smartphone'){
        display: flex;
        width: 100%;

        &+.questionnaire__question__answers__answer {
          margin-top: 15px;
        }
      }
      
      &:hover {
        .questionnaire__question__answers__answer__image {
          background-size: 105% 105%;
          box-shadow: inset 0px 0px 50px 50px rgba(0,0,0,0.1);
        }

        .questionnaire__question__answers__answer__title {
          color: #02162B;
        }
      }
    }

      .questionnaire__question__answers__answer__image {
        width: 100%;
        padding-top: 100%;
        background-size: 100% 100%;
        background-position: center;
        box-shadow: inset 0px 0px 50px 50px rgba(0,0,0,0);
        transition: all 300ms ease;

        @include mq('smartphone'){
          flex-shrink: 0;
          width: 100px;
          padding-top: 100px;
        }
      }

      .questionnaire__question__answers__answer__title {
        margin-top: 9px;
        font-family: $whitney;
        font-size: 24px;
        line-height: 26px;
        font-weight: 600;
        color: #00378C;
        transition: color 150ms ease;

        @include mq('smartphone'){
          width: 100%;
          padding-left: 20px;
          align-self: center;
          line-height: 20px;
          font-size: 20px;
        }
      }

      .questionnaire__question__answers__answer__description {
        padding-right: 28px;
        margin-top: 2px;
        font-family: $whitney;
        font-size: 16px;
        line-height: 22px;
        font-weight: 200;
        color: #000000;
      }

  .questionnaire__form {
    display: flex;
    flex-direction: column;

    .unit-input {
      padding-right: 75px;

      label {
        pointer-events: none;
      }
    }

    h2 {
      max-width: 820px;
      align-self: center;
      margin: 59px 0 32px;
      font-family: $whitney;
      font-size: 62px;
      line-height: 60px;
      font-weight: 400;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 0.17em;
    }

    .form__intro {
      display: flex;
      max-width: 100%;
      font-size: 28px;
      line-height: 36px;

      img {
        margin-right: 70px;
      }
    }

    .form__row--actions {
      justify-content: center;
    }
  }

  .questionnaire__back {
    align-self: flex-start;
    min-width: 154px;
  }

  .questionnaire-result__graph 
  {
    margin-top: 75px;
    padding: 60px 0 104px;
    background-color: #02162B;
  }

    .questionnaire-result__graph__title {
      margin-bottom: 60px;
      font-family: $whitney;
      font-size: 32px;
      line-height: 52px;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
    }

    .questionnaire-result__graph__wrapper {
        display: flex;
    }

      .questionnaire-result__graph__wrapper__plot {
        position: relative;
        width: 71%;
      }

        .questionnaire-result__graph__wrapper__plot__y-axis {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 48px;
          height: 412px;
          border-right: 1px solid rgba(#ffffff, 0.2);
        }

          .questionnaire-result__graph__wrapper__plot__y-axis__label {
            position: absolute;
            left: -45px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            font-family: $whitney;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            text-transform: uppercase;
          }

          .questionnaire-result__graph__wrapper__plot__y-axis__value {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 50%;
            font-family: $whitney;
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            color: #ffffff;
          }

            .questionnaire-result__graph__wrapper__plot__y-axis__value--value1 {
              padding-top: 60px;
            }

            .questionnaire-result__graph__wrapper__plot__y-axis__value--value2 {
              padding-bottom: 60px;
            }

        .questionnaire-result__graph__wrapper__plot__x-axis {
          display: flex;
          justify-content: center;
          position: absolute;
          bottom: -30px;
          left: 48px;
          right: 59px;
          height: 40px;
          border-top: 1px solid rgba(#ffffff, 0.2);
        }

          .questionnaire-result__graph__wrapper__plot__x-axis__label {
            position: absolute;
            bottom: -27px;
            left: 50%;
            transform: translateX(-50%);
            font-family: $whitney;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            text-transform: uppercase;
          }

          .questionnaire-result__graph__wrapper__plot__x-axis__value {
            padding-top: 27px;
            width: 50%;
            text-align: center;
            font-family: $whitney;
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            color: #ffffff;
          }

        .questionnaire-result__graph__wrapper__plot__content {
          position: absolute;
          top: 0;
          right: 50px;
          bottom: 0;
          left: 48px;
        }

          .questionnaire-result__graph__wrapper__plot__content__left {
            position: absolute;
            left: 128px;
            bottom: 117px;
            padding: 22px 44px 22px 28px;
            background-color: #F65275;
            border-radius: 14px;
            font-family: $whitney;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #ffffff;
            text-transform: uppercase;
            letter-spacing: 0.17em;
          }

            .questionnaire-result__graph__wrapper__plot__content__left__value {
              
            }

            .questionnaire-result__graph__wrapper__plot__content__left__label {
              position: absolute;
              left: 0;
              bottom: 0;
              transform: translateY(100%) translateY(5px);
              font-family: $whitney;
              font-size: 10px;
              line-height: 10px;
              font-weight: 600;
              color: #F65275;
              text-transform: uppercase;
              letter-spacing: 0.17em;
            }
            
        .questionnaire-result__graph__wrapper__plot__content__right {
          position: absolute;
          right: 5px;
          top: 95px;
          padding: 22px 44px 22px 28px;
          background-color: #00378C;
          border-radius: 14px;
          font-family: $whitney;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: #ffffff;
          text-transform: uppercase;
          letter-spacing: 0.17em;
        }

          .questionnaire-result__graph__wrapper__plot__content__right__value {
            
          }

          .questionnaire-result__graph__wrapper__plot__content__right__label {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(100%) translateY(5px);
            font-family: $whitney;
            font-size: 10px;
            line-height: 10px;
            font-weight: 600;
            color: #ffffff;
            text-transform: uppercase;
            letter-spacing: 0.17em;
          }

        .questionnaire-result__graph__wrapper__plot__content__arrow {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 30%;
          height: 30%;
          background-image: url('/assets/images/savings/graph-arrow.png');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          transform: translate(-36px, -70px);
        }
      
        .questionnaire-result__graph__wrapper__legend {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 29%;
        }

          .questionnaire-result__graph__wrapper__legend__top {
            display: flex;
            flex-direction: column;
            margin-left: 60px;
          }

            .questionnaire-result__graph__wrapper__legend__top__item {
              display: flex;
              margin-bottom: 30px;
            }

              .questionnaire-result__graph__wrapper__legend__top__item--pink {
                .questionnaire-result__graph__wrapper__legend__top__item__color {
                  background-color: #68D2DF;
                }

                .questionnaire-result__graph__wrapper__legend__top__item__label {
                  color: #ffffff;
                }
              }

              .questionnaire-result__graph__wrapper__legend__top__item--blue {
                .questionnaire-result__graph__wrapper__legend__top__item__color {
                  background-color: #F65275;
                }

                .questionnaire-result__graph__wrapper__legend__top__item__label {
                  color: #F65275;
                }
              }

              .questionnaire-result__graph__wrapper__legend__top__item__color {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: grey;
              }

              .questionnaire-result__graph__wrapper__legend__top__item__label {
                margin-left: 18px;
                font-family: $whitney;
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.17em;
                color: grey;
              }

          .questionnaire-result__graph__wrapper__legend__bottom {
            width: 100%;
            padding: 29px 35px 31px 39px;
            background-color: rgba(#00378B, 0.85);
          }

            .questionnaire-result__graph__wrapper__legend__bottom__title {
              margin-bottom: 20px;
              font-family: $whitney;
              font-size: 16px;
              line-height: 25px;
              font-weight: 400;
              text-transform: uppercase;
              letter-spacing: 0.17em;
              color: #ffffff;
            }

            .questionnaire-result__graph__wrapper__legend__bottom__row {
              font-family: $whitney;
              font-size: 14px;
              line-height: 29px;
              font-weight: 300;
              text-transform: uppercase;
              letter-spacing: 0.17em;
              color: #D4D4D4;

              b {
                display: inline-block;
                min-width: 60px;
                font-size: 19px;
                line-height: 29px;
                font-weight: 500;
                color: #ffffff;
              }
              
              span {
              }
            }
      

  .questionnaire-result {
    padding-top: 70px;
    flex-direction: column;

    @include mq('smartphone'){
      padding-top: 50px;
    }
    
    h2 {
      max-width: 820px;
      align-self: center;
      margin: 59px auto 32px;
      font-family: $whitney;
      font-size: 62px;
      line-height: 60px;
      font-weight: 400;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 0.17em;
    }

    .form__intro {
      display: flex;
      max-width: 100%;
      font-size: 28px;
      line-height: 36px;

      img {
        margin-right: 70px;
      }
    }

    .questionnaire__back {
      display: inline-flex;
    }
  }

    .questionnaire__result__expressedas {
      display: flex;
      justify-content: center;
      padding: 70px 0 80px;

      .inner {
        max-width: 936px
      }
    }

      .questionnaire__result__expressedas__title {
        margin-bottom: 40px;
        text-align: center;
        padding: 0 100px;
        font-family: $whitney;
        font-size: 32px;
        line-height: 52px;
        font-weight: 600;
        color: #000000;

        span {
          margin-top: -10px;
          font-size: 24px;
          display: block;
        }
      }

      .questionnaire__result__expressedas__row {
        display: flex;
        +.questionnaire__result__expressedas__row {
          margin-top: 50px;
        }
      }

        .questionnare__result__expressedas__row--disclaimer {

        }

        .questionnaire__result__expressedas__row__icon {
          width: 43px;
          height: 49px;
          margin-right: 44px;
          flex-shrink: 0;
          align-self: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .questionnaire__result__expressedas__row__info {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

          .questionnaire__result__expressedas__row__info__label {
            font-family: $whitney;
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;
            color: #D4D4D4;
          }

          .questionnaire__result__expressedas__row__info__name {
            font-family: $whitney;
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
            color: #00378C;
            text-transform: uppercase;
          }

        .questionnaire__result__expressedas__row__value {
          align-self: center;
          width: 215px;
          flex-shrink: 0;
          font-family: $whitney;
          font-size: 42px;
          line-height: 28px;
          font-weight: 400;
          color: #00378C;

          .small {
            padding-left: 56px;
            font-size: 18px;
          }
        }