.selectize-control {
  position: relative;
  flex-shrink: 0;
  width: 300px;
  height: 28px;
  border: none;
  border-bottom: 2px solid #82cfdc;
  z-index: 1;

  @media (max-width: 550px) {
    width: calc(100% - 100px) !important;
  }

  .selectize-input {
    position: relative;

    .item {
      font-family: $whitney;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #00378b;
      letter-spacing: 0.17em;
    }

    input {
      position: absolute !important;
      width: 0;
      height: 0;
      opacity: 0 !important;
      pointer-events: none !important;
    }

    &:after {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      width: 0;
      height: 0;
      margin: 5px;
      border: 5px solid transparent;
      border-top: 5px solid #00378B;
      border-right: 5px solid #00378B;
      transform: rotate(135deg);
      transform-origin: center center;
    }
  }

  .selectize-dropdown {
    top: auto !important;
    bottom: 0;

    .selectize-dropdown-content {
      padding: 5px 0;
      background: #ffffff;
      border-bottom: 2px solid #82cfdc;
      box-shadow: 0 2px 2px 2px rgba(#82cfdc, 0.1);
      max-height: 250px;
      overflow: auto;

      .option {
        padding: 5px;
        font-family: $whitney;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.17em;
        background-color: transparent;
        transition: all 250ms ease;

        &:hover {
          background-color: #eeeeee;
        }
        &.selected {
          background-color: #eeeeee;
        }
      }
    }
  }
}