@import "variables";
@import "breakpoints";

.form {
  margin: 70px 0;

  @include mq('smartphone'){
    margin: 30px 0;
  }
  

  .button {
    @include mq('smartphone'){
      margin-top: 20px;
    }
  }
}

  .form__intro {
    margin: 38px 0 35px;
    max-width: 520px;
    font-family: $whitney;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #00378C;
  }

  .form__suptitle {
    margin-bottom: 9px;
    font-family: $whitney;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.17em;
    font-weight: 350;
    text-transform: uppercase;
    color: #02162B;
  }

  .form__title {
    margin-bottom: 23px;
  }

  .form__description {
    margin-bottom: 65px;
    font-family: $whitney;
    font-size: 15px;
    line-height: 22px;
    font-weight: 350;
    color: #02162B;
  }

  .form__section {
    margin-top: 40px;
    border-top: 1px solid #D4D4D4;
    padding-top: 70px;
  }

    .form__section__title {
      margin-bottom: 40px;
      font-family: $whitney;
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      color: #02162B;
      text-transform: uppercase;
      letter-spacing: 0.17em;
    }

  .form__row {
    display: flex;
    justify-content: space-between;

    @include mq('smartphone'){
      flex-direction: column;
      justify-content: center;
    }

    millad-slider-input {
      @include mq('smartphone'){
        width: auto;
        align-self: flex-start;
      }
    }
  }

    .form__row__info {
      width: 100%;
      font-family: $whitney;
      font-size: 22px;
      line-height: 25px;
      font-weight: 400;
      color: #FF671F;
    }

    .form__row--double {
      millad-unit-input, millad-select-input, millad-slider-input {
        width: calc(50% - 50px);

        @include mq('tablet'){
          width: 50%;
        }

        @include mq('smartphone'){
          width: auto;
          align-self: flex-start;
        }

        @media (max-width: 550px) { 
          width: 100%;
        }

        .unit-input {
          @include mq('smartphone'){
            align-self: center;
          }
          
          .unit-input__value__input {
            @include mq('tablet'){
              width: 200px;
            }

            @include mq('smartphone'){
              width: 300px
            }
          }

          .unit-input__value__unit {
            @include mq('tablet'){
              left: 210px;
            }

            @include mq('smartphone'){
              left: 310px;
            }
          }
        }
      }

      >.unit-display, .unit-display__savings {
        width: calc(50% - 50px);

        @include mq('tablet'){
          width: 48%;
        }

        @include mq('smartphone'){
          width: 100%;
          align-self: center;
        }

        @media (max-width: 550px) { 
          width: 100%;
        }
      }
    }

    .form__row--margin-bottom {
      margin-bottom: 30px;
    }

    .form__row--actions {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      @include mq('smartphone'){
        flex-direction: column;
      }

      .button {
        width: calc(50% - 50px);

        @include mq('smartphone'){
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }

hr {
  margin: 52px 0 70px;
  border-color: #D4D4D4;

  @include mq('smartphone'){
    margin-top: 22px;
    margin-bottom: 40px;
  }
  
}

.info-with-checkbox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;

  @include mq('smartphone'){
    flex-direction: column;
  }
}

  .info-with-checkbox__info {
    max-width: 623px;
    font-family: $whitney;
    font-size: 15px;
    font-weight: 350;
    line-height: 22px;
    color: #000000;
  }

    .info-with-checkbox--only-info {
      .info-with-checkbox__info {
        max-width: 100%;
      }
    }

  .info-with-checkbox__checkbox {
    @include mq('smartphone'){
      margin-top: 30px;
    }
  }