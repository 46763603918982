@import "variables";
@import "breakpoints";

.button {
  position: relative;
  display: flex;
  justify-content: center;
  height: 50px;
  line-height: 46px;
  min-width: 300px;
  padding: 0 34px;
  align-self: center;
  font-family: $whitney;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  background-color: #063A89;
  border: 2px solid #063A89;
  border-radius: 25px;
  cursor: pointer;

  @include mq('smartphone'){
    min-width: 0;
    padding: 0 20px;
    font-size: 12px;
  }

  &:hover {
    background-color: #042E59;
    border-color: #042E59;
  }
}

.button--arrow {
  &:hover {
    &:before {
      margin-right: 0;
      margin-left: 13px;
    }
  }

  &:before, &:after {
    display: block;
    content: "";
    background-color: #ffffff;
    border-color: #ffffff;
  }

  &:before {
    align-self: center;
    order: 1;
    height: 2px;
    width: 9px;
    margin-right: -2px;
    margin-left: 10px;
    background-color: #ffffff;
    transition: margin 100ms ease;
  }

  &:after {
    align-self: center;
    order: 2;
    width: 0;
    height: 0;
    background-color: transparent !important;
    border: 5px solid;
    border-bottom: 5px solid transparent !important;
    border-left: 5px solid transparent !important;
    transform: rotate(45deg);
  }
}

.button--arrow--back { 
  &:hover {
    &:before {
      margin-left: 0;
      margin-right: 13px;
    }
  }

  &:before, &:after {
    display: block;
    content: "";
    background-color: #ffffff;
    border-color: #ffffff;
  }

  &:before {
    align-self: center;
    order: 0;
    height: 2px;
    width: 9px;
    margin-left: -2px;
    margin-right: 10px;
    background-color: #ffffff;
    transition: margin 100ms ease;
  }

  &:after {
    align-self: center;
    order: -1;
    width: 0;
    height: 0;
    background-color: transparent !important;
    border: 5px solid;
    border-top: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    transform: rotate(45deg);
  }
}

.button--border {
  background-color: transparent;
  color: #063A89;

  &:before, &:after {
    background-color: #063A89;
    border-color: #063A89;
  }

  &:hover {
    background-color: #063A89;
    border-color: #063A89;
    color: #ffffff;

    &:before, &:after {
      background-color: #ffffff;
      border-color: #ffffff;
    }
  }
}

.button--light-blue {
  background-color: #6CD1DD;
  border-color: #6CD1DD;
  color: #02162B;
  
  &:hover {
    background-color: #3dc4d3;
    border-color: #6CD1DD;
  }

  &:before, &:after {
    background-color: #02162B;
    border-color: #02162B;
  }

  &.button--border {
    background-color: transparent;
    color: #6CD1DD;
    border-color: #6CD1DD;
  
    &:before, &:after {
      background-color: #6CD1DD;
      border-color: #6CD1DD;
    }

    &:hover {
      background-color: #6CD1DD;
      color: #02162B;
  
      &:before, &:after {
        background-color: #02162B;
        border-color: #02162B;
      }
    }
  }
}

.container.buttons-container {
  margin: 50px 0;

  .inner {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
  }

  .button {
    min-width: 422px;
    align-self: center;
    
    @include mq('smartphone'){
      min-width: 0;
      max-width: 422px;
      width: 100%;
    }
    

    &+.button {
      margin-top: 30px;
    }
  }
}

.button--loading {
  position: relative;
  opacity: 0.5;
  pointer-events: none;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border: 2px dotted #063A89;
    background-color: #ffffff;
    border-radius: 50%;
    border-bottom: none;
    animation: 1.5s linear rotate infinite;
    position: absolute;
    right: 18px;
    top: 18px;
  }
}


@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}