@import "reset";
@import "variables";
@import "breakpoints";
@import "fonts";
@import "buttons";
@import "forms";
@import "mixins";
@import "typography";

* {
  box-sizing: border-box;
  
  &:focus, &:active {
    outline: none;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;

  .inner {
    max-width: 640px;
    width: 100%;
    padding: 0 20px;
  }

  .inner--medium {
    max-width: 1080px;
    margin: 0 auto;
  }

  .inner--big {
    max-width: 1240px;
    margin: 0 auto;
  }

  .inner--extra-big {
    max-width: 1260px;
    margin: 0 auto;
  }
}

.outside-parent {
  @include mq('desktop'){
    margin-left: -100px;
    margin-right: -100px;
  }
}

.container.intro {
  margin-top: 145px;
  margin-bottom: 78px;

  h1 {
    max-width: 480px;
    text-align: center;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 0.17em;
  }
}

.gradient {
  background: linear-gradient(180deg, rgba(0,55,140,1) 0%, rgba(0,53,134,1) 7%, rgba(2,18,34,1) 100%);
  color: #ffffff;

  * {
    color: #ffffff;
  }

  label, .unit-input__value__unit  {
    color: #D4D4D4 !important;
  }

  input {
    color: #ffffff !important;
  }
}

@import "components/unit-input";
@import "components/questionnaire";
@import "components/table";
@import "components/info";
@import "components/selectize";

