@import "../variables";
@import "../breakpoints";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.unit-input {
  margin-bottom: 30px;

  label {
    margin-bottom: 7px;
    display: inline-block;
    font-family: $whitney;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 0.17em;
  }
}

  .unit-input--readonly {
    label {
      color: #063a89;
      cursor: text;
    }

    .unit-input__value__input {
      border-color: #063a89;
    }
  }

  .unit-input--no-unit {
    width: 100%;

    .unit-input__value__input {
      width: 100% !important;
    }
  }

.unit-input__value {
  position: relative;
  display: flex;
}

.unit-input__pdf-value {
  display: none;
}

.unit-input__value__input {
  flex-shrink: 0;
  width: 300px;
  height: 28px;
  border: none;
  border-bottom: 2px solid #82cfdc;

  @media (max-width: 550px) {
    width: calc(100% - 100px) !important;
  }

  &:focus {
    border-color: #063a89;
  }

  &.error {
    border-color: #ff0000;
  }

  &[disabled] {
    border-bottom-color: transparent;
  }

  &[readonly] {
    border-bottom-color:  #d4d4d4;
    color: #888888;
  }
}

input.unit-input__value__input {
  font-family: $whitney;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #00378b;
  letter-spacing: 0.17em;
  background-color: transparent;
  text-transform: uppercase;
}

.unit-input__value__unit {
  position: absolute;
  left: 310px;
  font-family: $whitney;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #d4d4d4;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  white-space: nowrap;


  @media (max-width: 550px) {
    position: static;
    min-width: 110px;
    margin-left: 10px;
    transform: none;
  }
}

.unit-input__error {
  margin-top: 2px;
  font-family: $whitney;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  letter-spacing: 0.17em;
  text-transform: uppercase;
  color: #ff0000;
}
