@import "../variables";
@import "../breakpoints";

.table {
  width: 100%;
  border: 1px solid #D4D4D4;

  th, td {
    padding: 25px 30px 20px;
    font-family: $whitney;
    font-size: 12px;
    line-height: 13px;
    font-weight: 600;
    color: #000000;
    text-align: center;
    border-left: 1px solid #D4D4D4;
    border-right: 1px solid #D4D4D4;
    text-transform: uppercase;
    letter-spacing: 0.17em;

    &.table__align-left {
      text-align: left;
    }
  }

  thead {
    tr {
      background-color: #ffffff;
      th {
        &.table__align-left {
          text-align: left;
        }

        &.table__col1 {
          color: #00378C;
        }

        &.table__col2 {
          color: #68D2DF;
        }

        &.table__col3 {
          color: #F65275;
        }
      }
    }
  }

  tbody {
    tr {
      background-color: #ffffff;

      &:nth-of-type(odd){ 
        background-color: rgba(#00378C, 0.05);
      }
    
      td {

      }
    }
  }
}