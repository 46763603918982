.info {
  max-width: 850px;
  width: 100%;
  display: block;
  margin: 0 auto;
  padding-top: 235px;
  padding-bottom: 100px;
  justify-content: center;
  font-size: 1em;
  line-height: 1.35;
  font-family: "Whitney", Arial, Helvetica, sans-serif, sans-serif;

  h1,
  h2,
  h3,
  h4 {
    color: black;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  h4 {
    font-weight: 600;
  }
  p {
    margin-bottom: 20px;

    .bolded {
      font-weight: bold;
    }
  }

  .links p {
    margin: 0;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}
